import * as types from './types';
import r from '../../config/routes';

const initial = [{
  label: 'Dashboard',
  path: r.Dashboard,
}
];

const auth = (state = initial, action) => {
  switch (action.type) {
  case types.SET_PATH:
    return [...action.payload];
  default:
    return state;
  }
};

export default auth;
