import { connect } from 'react-redux'
import Component from './component'

const mapStateToProps = (state, ownProps) => ({
  breadcrumbs: state.breadcrumbs,
})

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component)
