import * as types from './types';
// import _ from 'underscore';

const initial = {
  setting: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
  loading: false
};

const settings = (state = initial, action) => {
  switch (action.type) {
  case types.GET_SETTING_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_SETTING_REQUEST_SUCCESS:
    return {
      ...state,
      setting: action.payload.data,
      loading: false
    };
  case types.GET_SETTING_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_SETTING_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_SETTING_REQUEST_SUCCESS:
    return {
      ...state,
      setting: null,
      loading: false
    };
  case types.UPDATE_SETTING_REQUEST_FAILURE:
    return {
      ...state,
      setting: null,
      loading: false
      // message: action.payload.message
    };
  default:
    return state;
  }
};

export default settings;
