import * as types from './types';

const initial = {
  loading: false,
  lender_id: null,
  lender: null,
  list: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
};

export default (state = initial, action) => {
  switch (action.type) {
  case types.LIST_LENDERS_USERS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.LIST_LENDERS_USERS_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload.data,
      loading: false
      // message: action.payload.message
    };
  case types.LIST_LENDERS_USERS_REQUEST_FAILURE:
    return {
      ...state,
      list: null,
      loading: false,
      message: action.payload.message
    };
  case types.CREATE_LENDER_USER_REQUEST:
    console.log(types.CREATE_LENDER_USER_REQUEST)
    return {
      ...state,
      loading: true
    };
  case types.CREATE_LENDER_USER_REQUEST_SUCCESS:
    console.log(types.CREATE_LENDER_USER_REQUEST_SUCCESS)
    return {
      ...state,
      lender_id: action.payload.data,
      lender: null,
      loading: false
      // message: action.payload.message
    };
  case types.CREATE_LENDER_USER_REQUEST_FAILURE:
    console.log(types.CREATE_LENDER_USER_REQUEST_FAILURE)
    return {
      ...state,
      lender_id: null,
      lender: null,
      loading: false,
      message: action.payload.message
    };
  default:
    return state;
  }
};
