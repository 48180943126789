export const GET_SUPER_ADMINS_REQUEST = 'GET_SUPER_ADMINS_REQUEST';
export const GET_SUPER_ADMINS_REQUEST_SUCCESS = 'GET_SUPER_ADMINS_REQUEST_SUCCESS';
export const GET_SUPER_ADMINS_REQUEST_FAILURE = 'GET_SUPER_ADMINS_REQUEST_FAILURE';

export const GET_SUPER_ADMIN_REQUEST = 'GET_SUPER_ADMIN_REQUEST';
export const GET_SUPER_ADMIN_REQUEST_SUCCESS = 'GET_SUPER_ADMIN_REQUEST_SUCCESS';
export const GET_SUPER_ADMIN_REQUEST_FAILURE = 'GET_SUPER_ADMIN_REQUEST_FAILURE';

export const CREATE_SUPER_ADMINS_REQUEST = 'CREATE_SUPER_ADMINS_REQUEST';
export const CREATE_SUPER_ADMINS_REQUEST_SUCCESS = 'CREATE_SUPER_ADMINS_REQUEST_SUCCESS';
export const CREATE_SUPER_ADMINS_REQUEST_FAILURE = 'CREATE_SUPER_ADMINS_REQUEST_FAILURE';

export const UPDATE_SUPER_ADMINS_REQUEST = 'UPDATE_SUPER_ADMINS_REQUEST';
export const UPDATE_SUPER_ADMINS_REQUEST_SUCCESS = 'UPDATE_SUPER_ADMINS_REQUEST_SUCCESS';
export const UPDATE_SUPER_ADMINS_REQUEST_FAILURE = 'UPDATE_SUPER_ADMINS_REQUEST_FAILURE';

export const CHANGE_PASSWORD_SUPER_ADMINS_REQUEST = 'CHANGE_PASSWORD_SUPER_ADMINS_REQUEST';
export const CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_SUCCESS = 'CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_FAILURE = 'CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_FAILURE';
