/* eslint-disable import/prefer-default-export */
import { RSAA } from "redux-api-middleware";
import * as types from "./types";

import { API_URL } from "../../config/env";

export const getClientsUsers = clientId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users`,
    method: "GET",
    headers: {
      "Content-Type": "applicaiton/json",
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
    }
  }
});

export const createClientsUsers = (clientId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.CREATE_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users`,
    method: "POST",
    headers: {
      "Content-Type": "applicaiton/json",
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
    },
    body: JSON.stringify({
      role: data.role,
      username: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      bio: data.bio,
      address: data.address,
      job_position: data.jobPosition,
      payroll_amount: parseFloat(data.payrollAmount),
      payment_schedule: data.paymentSchedule,
      allowance: parseFloat(data.allowance)
    })
  }
});

export const updateClientsUserRole = (clientId, clientUserId, role) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${clientUserId}/role`,
    method: "PATCH",
    headers: {
      "Content-Type": "applicaiton/json",
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
    },
    body: JSON.stringify({ role })
  }
});

export const updateClientsUsers = (clientId, clientUserId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${clientUserId}/by-admin`,
    method: "PATCH",
    headers: {
      "Content-Type": "applicaiton/json",
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
    },
    body: JSON.stringify({
      logo: data.logo,
      first_name: data.firstName,
      last_name: data.lastName,
      bio: data.bio,
      address: data.address,
      job_position: data.jobPosition,
      payroll_amount: parseFloat(data.payrollAmount),
      payment_schedule: data.paymentSchedule,
      allowance: parseFloat(data.allowance),
      traditional_loan: {
        credit_limit: data.traditionalLoanPreApprovalAmount,
        repayment_options: data.paymentOptions,
        repayment_day_options: data.paySchedule
      }
    })
  }
});

export const resendConfirmation = (clientId, userIds) => ({
  [RSAA]: {
    types: [
      {
        type: types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/resend-confirmation`,
    method: "PATCH",
    headers: {
      "Content-Type": "applicaiton/json",
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
    },
    body: JSON.stringify({
      user_ids: userIds
    })
  }
});

export const getClientsUser = (clientId, clientUserId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENTS_USER_REQUEST,
        payload: (_action, _state, res) => clientId
      },
      {
        type: types.GET_CLIENTS_USER_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_USER_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-users/${clientId}/users/${clientUserId}`,
    method: "GET",
    headers: {
      "Content-Type": "applicaiton/json",
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
    }
  }
});
