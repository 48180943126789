import * as types from './types';
import _ from 'underscore';

const initial = {
  addedNew: false, // XXX: We PROBABLY don't need it anymore
  superAdmin: null,
  user: null, // XXX: We probably don't need it.
  list: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
  loading: false,
};

const superAdmins = (state = initial, action) => {
  switch (action.type) {
  case types.GET_SUPER_ADMINS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_SUPER_ADMINS_REQUEST_SUCCESS:
    return {
      ...state,
      addedNew: false,
      list: (_.isArray(action.payload.data) ? action.payload.data : []),
      loading: false
    };
  case types.GET_SUPER_ADMIN_REQUEST_SUCCESS:
    return {
      ...state,
      superAdmin: action.payload.data,
      loading: false
    };
  case types.CREATE_SUPER_ADMINS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.CREATE_SUPER_ADMINS_REQUEST_SUCCESS:
    return {
      ...state,
      addedNew: true,
      loading: false
    };
  case types.CREATE_SUPER_ADMINS_REQUEST_FAILURE:
    return {
      ...state,
      message: action.payload.message,
      loading: false
    };
  case types.UPDATE_SUPER_ADMINS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_SUPER_ADMINS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_SUPER_ADMINS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
      // message: action.payload.message
    };
  case types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_FAILURE:
    return {
      ...state,
      message: action.payload.message,
      loading: false
    };
  default:
    return state;
  }
};

export default superAdmins;
