import * as types from './types';

const initial = {
  loading: false,
  lenderId: null,
  entryId: null,
  wallet: null,
  walletTotals: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
};

export default (state = initial, action) => {
  switch (action.type) {
  case types.GET_LENDERS_WALLET_TOTALS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_LENDERS_WALLET_TOTALS_REQUEST_SUCCESS:
    return {
      ...state,
      walletTotals: action.payload.data,
      loading: false
      // message: action.payload.message
    };
  case types.GET_LENDERS_WALLET_TOTALS_REQUEST_FAILURE:
    return {
      ...state,
      walletTotals: null,
      loading: false,
      message: action.payload.message
    };
  case types.GET_LENDERS_WALLET_ALL_REQUEST:
    return {
      ...state,
      loading: true,
      wallet: null
    };
  case types.GET_LENDERS_WALLET_ALL_REQUEST_SUCCESS:
    return {
      ...state,
      wallet: (action.payload.data === null) ? [] : action.payload.data,
      loading: false
      // message: action.payload.message
    };
  case types.GET_LENDERS_WALLET_ALL_REQUEST_FAILURE:
    return {
      ...state,
      wallet: null,
      loading: false,
      message: action.payload.message
    };
  case types.MARK_DEPOSIT_AS_VERIFIED_REQUEST:
    return {
      ...state,
      loading: true,
      lenderId: null,
      entryId: null
    };
  case types.MARK_DEPOSIT_AS_VERIFIED_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      lenderId: action.payload.req.lenderId,
      entryId: action.payload.req.entryId
      // message: action.payload.message
    };
  case types.MARK_DEPOSIT_AS_VERIFIED_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      message: action.payload.message,
      lenderId: action.payload.req.lenderId,
      entryId: action.payload.req.entryId
    };
  case types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST:
    return {
      ...state,
      loading: true,
      lenderId: null,
      entryId: null
    };
  case types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      lenderId: action.payload.req.lenderId,
      entryId: action.payload.req.entryId
      // message: action.payload.message
    };
  case types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      message: action.payload.message,
      lenderId: action.payload.req.lenderId,
      entryId: action.payload.req.entryId
    };
  default:
    return state;
  }
};
