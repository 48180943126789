/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getWalletTotals = lenderId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_LENDERS_WALLET_TOTALS_REQUEST
      },
      {
        type: types.GET_LENDERS_WALLET_TOTALS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LENDERS_WALLET_TOTALS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/lenders-wallets/${lenderId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const getWallet = lenderId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_LENDERS_WALLET_ALL_REQUEST
      },
      {
        type: types.GET_LENDERS_WALLET_ALL_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LENDERS_WALLET_ALL_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/lenders-wallets/${lenderId}/all`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const markDepositAsVerified = (lenderId, entryId) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_DEPOSIT_AS_VERIFIED_REQUEST
      },
      {
        type: types.MARK_DEPOSIT_AS_VERIFIED_REQUEST_SUCCESS,
        payload: (_action, _state, res) => ({
          req: { lenderId, entryId },
          resp: res.json()
        })
      },
      {
        type: types.MARK_DEPOSIT_AS_VERIFIED_REQUEST_FAILURE,
        payload: (_action, _state, res) => ({
          req: { lenderId, entryId },
          resp: res.json()
        })
      }
    ],
    endpoint: `${API_URL}/lenders-wallets/${lenderId}/deposits/${entryId}/verified`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const markWithdrawalAsDelivered = (lenderId, entryId) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST
      },
      {
        type: types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_SUCCESS,
        payload: (_action, _state, res) => ({
          req: { lenderId, entryId },
          resp: res.json()
        })
      },
      {
        type: types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_FAILURE,
        payload: (_action, _state, res) => ({
          req: { lenderId, entryId },
          resp: res.json()
        })
      }
    ],
    endpoint: `${API_URL}/lenders-wallets/${lenderId}/withdrawals/${entryId}/delivered`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});