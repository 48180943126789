import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import logoImage from '../../assets/images/paymachine.svg';
import styles from './layout-none.style';
import './style.css';

const NoLayout = (props) => {
  const {
    classes,
    children
  } = props;
  return (
    <div className={classes.wrapper}>
      <div className="logo">
        <a href="/">
          <img src={logoImage} className="logo-image" alt="logo" />
          <span>
            PayMachine
          </span>
        </a>
      </div>
      {children}
    </div>
  );
};

NoLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(NoLayout);

