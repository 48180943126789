import React from 'react';

const Breadcrumbs = (props) => {
  const style = {
    ul: {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    li: {
      float: 'left',
      fontSize: '13px',
      fontWeight: '300',
      color: 'grey'
    },
    link: {
      color: 'grey',
      textDecoration: 'none'
    },
    separator: {
      fontSize: '10px',
      padding: '0 3px'
    }
  };

  const items = [];
  const l = props.breadcrumbs.length;
  props.breadcrumbs.forEach((item, idx) => {
    let { label } = item;
    if (item.path && item.path !== null) {
      label = <a key={'breadcrumb-' + idx} href={item.path} style={style.link}>{item.label}</a>;
    }

    if (idx + 1 === l) { // last element - do not display icon or link
      label = item.label;
      items.push(<li key={'breadcrumb-' + idx} style={style.li}>{label}</li>);
    } else {
      items.push(<li key={'breadcrumb-' + idx} style={style.li}>{label} <span style={style.separator}>/</span></li>);
    }
  });

  return (<ul style={style.ul}>{items}</ul>);
};


export default Breadcrumbs;
