import * as types from './types';

const initState = {
  fees: null,
  loading: false,
  error: false
}

const feesState = (state = initState, action) => {
  switch (action.type) {
    case types.UPDATE_FEES: {
      return { ...state, loading: true, error: false };
    }
    case types.UPDATE_FEES_SUCCESS: {
      return { ...state, loading: false, error: false };
    }
    case types.UPDATE_FEES_FAILURE: {
      return { ...state, loading: false, error: true };
    }
    default: {
      return state;
    }
  }
}

export default feesState;