import {put, takeEvery} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as types from './types';

import r from '../../config/routes';
import {getSuperAdmins} from './actions';
import {showConfirmationInfo} from "../app-state/actions";

function* afterCreateSuperAdminsSuccess() {
  yield put(getSuperAdmins());
  yield put(push(r.SuperAdminsList));
  yield put(showConfirmationInfo('Created super admin!'));
}

function* afterChangePassword() {
  yield put(showConfirmationInfo("Successfully changed password"))
}

function* afterUpdateUpdateSuperAdminSuccess() {
  yield put(showConfirmationInfo('Successfully updated user!'))
}


export default function* authSaga() {
  yield takeEvery(types.CREATE_SUPER_ADMINS_REQUEST_SUCCESS, afterCreateSuperAdminsSuccess);
  yield takeEvery(types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_SUCCESS, afterChangePassword);
  yield takeEvery(types.UPDATE_SUPER_ADMINS_REQUEST_SUCCESS, afterUpdateUpdateSuperAdminSuccess);
}
