
import withTheme from '@material-ui/core/styles/withTheme';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import { connect } from 'react-redux';
import Component from './component';

import { toggleSidenav } from '../../../../actions/layout.actions';
import { logout } from '../../../../redux/auth/actions';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  layout: {
    sidenavOpen: state.layout.sidenavOpen
  },
  isWidthDown,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  toggleSidenav: () => dispatch(toggleSidenav()),
});



export default withTheme(compose(
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Component));
