export const GET_LENDERS_WALLET_TOTALS_REQUEST = 'GET_LENDERS_WALLET_TOTALS_REQUEST';
export const GET_LENDERS_WALLET_TOTALS_REQUEST_SUCCESS = 'GET_LENDERS_WALLET_TOTALS_REQUEST_SUCCESS';
export const GET_LENDERS_WALLET_TOTALS_REQUEST_FAILURE = 'GET_LENDERS_WALLET_TOTALS_REQUEST_FAILURE';

export const GET_LENDERS_WALLET_ALL_REQUEST = 'GET_LENDERS_WALLET_ALL_REQUEST';
export const GET_LENDERS_WALLET_ALL_REQUEST_SUCCESS = 'GET_LENDERS_WALLET_ALL_REQUEST_SUCCESS';
export const GET_LENDERS_WALLET_ALL_REQUEST_FAILURE = 'GET_LENDERS_WALLET_ALL_REQUEST_FAILURE';

export const MARK_DEPOSIT_AS_VERIFIED_REQUEST = 'MARK_DEPOSIT_AS_VERIFIED_REQUEST';
export const MARK_DEPOSIT_AS_VERIFIED_REQUEST_SUCCESS = 'MARK_DEPOSIT_AS_VERIFIED_REQUEST_SUCCESS';
export const MARK_DEPOSIT_AS_VERIFIED_REQUEST_FAILURE = 'MARK_DEPOSIT_AS_VERIFIED_REQUEST_FAILURE';

export const MARK_WITHDRAWAL_AS_DELIVERED_REQUEST = 'MARK_WITHDRAWAL_AS_DELIVERED_REQUEST';
export const MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_SUCCESS = 'MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_SUCCESS';
export const MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_FAILURE = 'MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_FAILURE';
