import * as types from './types';

const initial = {
  loading: false,
  lender_id: null,
  lender: null,
  list: null,
  wallet: null,
  walletTotals: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
};

export default (state = initial, action) => {
  switch (action.type) {
  case types.SHOW_LENDER_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.SHOW_LENDER_REQUEST_SUCCESS:
    return {
      ...state,
      lender: action.payload.data,
      loading: false
      // message: action.payload.message
    };
  case types.SHOW_LENDER_REQUEST_FAILURE:
    return {
      ...state,
      lender: null,
      loading: false,
      message: action.payload.message
    };
  case types.LIST_LENDERS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.LIST_LENDERS_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload.data,
      loading: false
      // message: action.payload.message
    };
  case types.LIST_LENDERS_REQUEST_FAILURE:
    return {
      ...state,
      list: null,
      loading: false,
      message: action.payload.message
    };
  case types.CREATE_LENDER_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.CREATE_LENDER_REQUEST_SUCCESS:
    return {
      ...state,
      lender_id: action.payload.data,
      lender: null,
      loading: false
      // message: action.payload.message
    };
  case types.CREATE_LENDER_REQUEST_FAILURE:
    return {
      ...state,
      lender_id: null,
      lender: null,
      loading: false,
      message: action.payload.message
    };
  case types.MARK_VERIFIED_LENDER_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.MARK_VERIFIED_LENDER_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
      // message: action.payload.message
    };
  case types.MARK_VERIFIED_LENDER_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      message: action.payload.message
    };
  default:
    return state;
  }
};
