import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import MenuSidenavItem from '@paymachine/shared/lib/components/menu-sidenav-item/menu-sidenav-item.component';
import styles from './menu-sidenav.style';

import { menuItems } from '../../../../config';

class MenuSidenav extends React.Component {
  handleClick = (element, event) => {
    console.log(this.props.variant)
    if (!element.children && this.props.variant !== 'persistent') {
      this.props.closeSidenav();
    }
    if (element.onClick) { element.onClick(event); }
  };
  constructNavItems = (itemsArray, cls) => {
    const arr = itemsArray.map(el => (
      <MenuSidenavItem
        title={el.title}
        href={el.href}
        key={el.title}
        icon={el.icon}
        type={el.type}
        style={el.style}
        onClick={(ev) => this.handleClick(el, ev)}
      >
        {el.children && this.constructNavItems(el.children, cls)}
      </MenuSidenavItem>));
    return (
      <List
        className={cls.list}
        style={{
          minHeight: '100%',
          paddingTop: 0,
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {(arr.slice(0, -1))}
        <div style={{ flex: 1 }} />
        {(arr.slice(-1))}
      </List>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          {this.constructNavItems(menuItems, classes)}
        </div>
      </div>
    );
  }
}

MenuSidenav.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  variant: PropTypes.string.isRequired
};

export default withStyles(styles)(MenuSidenav);
