import {put, select, takeEvery} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as types from './types';

import r from '../../config/routes';
import {getClientsUsers} from './actions';
import {showConfirmationInfo} from "../app-state/actions";

function* afterCreateClientUserSuccess() {
  const state = yield select();

  yield put(push(`${r.ClientsEdit.replace(':client_id', state.clientsUsers.clientId)}#users`));
  yield put(getClientsUsers(state.clientsUsers.clientId));
  yield put(showConfirmationInfo('Created client user!'));
}

function* afterUpdateClientUserSuccess() {
  const state = yield select();

  yield put(push(`${r.ClientsEdit.replace(':client_id', state.clientsUsers.clientId)}#users`));
  yield put(getClientsUsers(state.clientsUsers.clientId));
  yield put(showConfirmationInfo('Updated client user!'));
}

function* afterResendConfirmationClientUserSuccess() {
  const state = yield select();

  yield put(push(`${r.ClientsEdit.replace(':client_id', state.clientsUsers.clientId)}#users`));
  yield put(getClientsUsers(state.clientsUsers.clientId));
  yield put(showConfirmationInfo('Resent confirmation!'));
}

export default function* authSaga() {
  yield takeEvery(types.CREATE_CLIENTS_USERS_REQUEST_SUCCESS, afterCreateClientUserSuccess);
  yield takeEvery(types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS, afterUpdateClientUserSuccess);
  yield takeEvery(types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_SUCCESS, afterResendConfirmationClientUserSuccess);
}
