import * as types from './types';
// import _ from 'underscore';

const initial = {
  loading: false,
  client_id: null,
  client: null,
  list: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
  lenders: []
};

const clients = (state = initial, action) => {
  switch (action.type) {
  case types.UPDATE_CLIENT_FEE_MODEL_REQUEST:
    return {
      ...state,
      loading: true,
      message: null
    };
  case types.UPDATE_CLIENT_FEE_MODEL_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      message: null
    };
  case types.UPDATE_CLIENT_FEE_MODEL_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      message: action.payload.message
    };
  case types.UPDATE_CLIENT_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_CLIENT_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_CLIENT_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.GET_CLIENTS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_CLIENTS_REQUEST_SUCCESS:
    return {
      ...state,
      list: (action.payload.data === null) ? [] : action.payload.data,
      loading: false,
    };
  case types.CREATE_CLIENT_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.CREATE_CLIENT_REQUEST_SUCCESS:
    return {
      ...state,
      client_id: action.payload.data,
      client: null,
      loading: false
      // message: action.payload.message
    };
  case types.GET_CLIENT_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_CLIENT_REQUEST_SUCCESS:
    return {
      ...state,
      client: action.payload.data,
      loading: false
      // message: action.payload.message
    };
  case types.GET_LENDERS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_LENDERS_REQUEST_SUCCESS:
    return {
      ...state,
      lenders: action.payload.data,
      loading: false
    };
  case types.GET_LENDERS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  default:
    return state;
  }
};

export default clients;
