import lightBlue from '@material-ui/core/colors/lightBlue';
import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';

export const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tabLabel: {
    maxWidth: '100%',
    textTransform: 'capitalize'
  },
  toggleContainer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  'portal-backlog-widget': {
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative',
    '& :last-child': {
      paddingBottom: 0
    }
  },
  'portal-backlog-widget__table': {
    width: '100%',
    position: 'relative',
    padding: 0,
    overflowX: 'auto'
  },
  'table-cell': {
    padding: '4px 24px 4px 24px'
  },
  mainLayout: {
    backgroundColor: paymachineColors.Main.background
  },
  confirmationInfo: {
    color: 'white',
    backgroundColor: green[600]
  },
  errorInfo: {
    color: 'white',
    backgroundColor: red[600]
  },
  portalDashboardPageWrapper: {
    padding: 16,
    minHeight: '100%',
    boxSizing: 'border-box'
  },
  portalWidget: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column'
  },
  portalWidgetHeading: {
    textTransform: 'uppercase',
    paddingLeft: 12,
    marginTop: 16,
    marginBottom: 16,
    color: '#000000',
    '&:after': {
      content: '""',
      width: 2,
      height: '0%',
      position: 'absolute',
      bottom: 0,
      left: -2,
      transition: 'height .5s',
      backgroundColor: theme.palette.secondary.main
    }
  },
  portalWidgetContent: {
    flex: '1 1 100%'
  }
});

export const paymachineColors = {
  50: '#E3F2FD',
  100: '#BBDEFB',
  200: '#90CAF9',
  300: '#21293e',
  400: '#42A5F5',
  500: '#058bd4',
  600: '#1E88E5',
  700: '#058bd4',
  800: '#1565C0',
  900: '#0D47A1',
  A100: '#FF80AB',
  A200: '#FF4081',
  A400: '#058bd4',
  A700: '#C51162',
  Main: {
    background: '#058bd4'
  },
  SideNav: {
    background: '#21293e'
  }
}

const paymachineContentTheme = {
  direction: 'ltr',
  typography: {
    // fontFamily: 'Barlow',
    fontFamily: 'Poppins',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'light',
    primary: paymachineColors,
    secondary: paymachineColors,
    background: {
      default: paymachineColors.Main.background
    }
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF',
        color: '#000'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#323232',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#323232',
        }
      },
      containedSecondary: {
        backgroundColor: '#dadada',
        color: '#323232',
        '&:hover': {
          backgroundColor: '#dadada',
        }
      },
      textPrimary: {
        color: '#323232',
        '&:hover': {
          backgroundColor: 'none',
        }
      },
      textSecondary: {
        color: '#323232',
        '&:hover': {
          backgroundColor: 'none',
        }
      },
    }
  }
};

const paymachineSidenavTheme = {
  ...paymachineContentTheme,
  palette: {
    ...paymachineContentTheme.palette,
    type: 'dark',
    background: {
      default: paymachineColors.SideNav.background,
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: paymachineColors.SideNav.background,
        color: '#FFF'
      }
    }
  }
};

const defaultContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Poppins',
    // fontFamily: 'Barlow',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'light',
    primary: lightBlue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF',
        color: '#000'
      }
    }
  }
};

const defaultSidenavTheme = {
  ...defaultContentTheme,
  palette: {
    ...defaultContentTheme.palette,
    type: 'dark',
    background: {
      default: '#424242'
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#424242',
        color: '#FFF'
      }
    }
  }
};

const darkContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Poppins',
    // fontFamily: 'Barlow',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'light',
    primary: lightBlue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#424242',
        color: '#FFF'
      }
    }
  }
};

const nightContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Poppins',
    // fontFamily: 'Barlow',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'dark',
    primary: lightBlue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#424242',
        color: '#FFF'
      }
    }
  }
};

const themes = [
  {
    id: 'paymachine-default',
    name: 'PayMachine default',
    theme: {
      contentTheme: paymachineContentTheme,
      sidenavTheme: paymachineSidenavTheme
    }
  },{
  id: 'classic-light_blue-pink',
  name: 'Classic - Light Blue / Pink',
  theme: {
    contentTheme: defaultContentTheme,
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'classic-green-deep_orange',
  name: 'Classic - Green / Deep Orange',
  theme: {
    contentTheme: {
      ...defaultContentTheme,
      palette: {
        type: 'light',
        primary: green,
        secondary: deepOrange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'classic-indigo-orange',
  name: 'Classic - Indigo / Orange',
  theme: {
    contentTheme: {
      ...defaultContentTheme,
      palette: {
        type: 'light',
        primary: indigo,
        secondary: orange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'dark-light_blue-pink',
  name: 'Dark - Light Blue / Pink',
  theme: {
    contentTheme: darkContentTheme,
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'dark-green-deep_orange',
  name: 'Dark - Green / Deep Orange',
  theme: {
    contentTheme: {
      ...darkContentTheme,
      palette: {
        type: 'light',
        primary: green,
        secondary: deepOrange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'dark-indigo-orange',
  name: 'Dark - Indigo / Orange',
  theme: {
    contentTheme: {
      ...darkContentTheme,
      palette: {
        type: 'light',
        primary: indigo,
        secondary: orange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'night-light_blue-pink',
  name: 'Night - Light Blue / Pink',
  theme: {
    contentTheme: nightContentTheme,
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'night-green-deep_orange',
  name: 'Night - Green / Deep Orange',
  theme: {
    contentTheme: {
      ...nightContentTheme,
      palette: {
        type: 'dark',
        primary: green,
        secondary: deepOrange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'night-indigo-orange',
  name: 'Night - Indigo / Orange',
  theme: {
    contentTheme: {
      ...darkContentTheme,
      palette: {
        type: 'dark',
        primary: indigo,
        secondary: orange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}];

export default themes;
