import * as types from './types';
import _ from 'underscore';
import moment from 'moment';

const initial = {
  loading: false,
  login_time: null,
  username: null,
  token: null,
  access_token: null,
  id: null,
  authenticated: false,
  message: null, //{msg: 'User authenticated', type: 'info'}
};

const auth = (state = initial, action) => {
  switch (action.type) {
  case types.TRY_TO_AUTHENTICATE:
    const { token, username, id, access_token } = action.payload;

    if (!_.isEmpty(token) && !_.isEmpty(username)) {
      return {
        ...state,
        authenticated: true,
        username,
        token,
        access_token,
        id,
      };
    }

    return {...state};
  case types.LOGIN_REQUEST:
    return {
      ...state,
      username: action.payload.username,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: null,
      login_time: null,
      loading: true
    };
  case types.LOGIN_REQUEST_SUCCESS:
    return {
      ...state,
      access_token: action.payload.data.access_token,
      token: action.payload.data.token,
      id: action.payload.data.id,
      login_time: moment().format(),
      authenticated: true,
      message: null,
      loading: false
    };
  case types.LOGIN_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      login_time: null,
      loading: false
    };
  case types.LOGOUT:
    return {
      ...state,
      username: null,
      authenticated: false,
      token: null,
      access_token: null,
      id: null,
    };
  case types.REGISTER_REQUEST:
    return {
      ...state,
      username: action.payload.username,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: null,
      loading: true
    };
  case types.REGISTER_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.REGISTER_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  case types.REGISTER_CONFIRM_REQUEST:
    return {
      ...state,
      message: null,
      loading: true
    };
  case types.REGISTER_CONFIRM_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  case types.RESET_PASSWORD_REQUEST:
    return {
      ...state,
      username: action.payload.username,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: null,
      loading: true
    };
  case types.RESET_PASSWORD_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.RESET_PASSWORD_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  case types.RESET_PASSWORD_CONFIRM_REQUEST:
    return {
      ...state,
      message: null,
      loading: true
    };
  case types.RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.RESET_PASSWORD_CONFIRM_REQUEST_FAILURE:
    return {
      ...state,
      username: null,
      token: null,
      access_token: null,
      id: null,
      authenticated: false,
      message: action.payload.message,
      loading: false
    };
  default:
    return state;
  }
};

export default auth;
