export const CREATE_LENDER_REQUEST = 'CREATE_LENDER_REQUEST';
export const CREATE_LENDER_REQUEST_SUCCESS = 'CREATE_LENDER_REQUEST_SUCCESS';
export const CREATE_LENDER_REQUEST_FAILURE = 'CREATE_LENDER_REQUEST_FAILURE';

export const LIST_LENDERS_REQUEST = 'LIST_LENDERS_REQUEST';
export const LIST_LENDERS_REQUEST_SUCCESS = 'LIST_LENDERS_REQUEST_SUCCESS';
export const LIST_LENDERS_REQUEST_FAILURE = 'LIST_LENDERS_REQUEST_FAILURE';

export const SHOW_LENDER_REQUEST = 'SHOW_LENDER_REQUEST';
export const SHOW_LENDER_REQUEST_SUCCESS = 'SHOW_LENDER_REQUEST_SUCCESS';
export const SHOW_LENDER_REQUEST_FAILURE = 'SHOW_LENDER_REQUEST_FAILURE';

export const MARK_VERIFIED_LENDER_REQUEST = 'MARK_VERIFIED_LENDER_REQUEST';
export const MARK_VERIFIED_LENDER_REQUEST_SUCCESS = 'MARK_VERIFIED_LENDER_REQUEST_SUCCESS';
export const MARK_VERIFIED_LENDER_REQUEST_FAILURE = 'MARK_VERIFIED_LENDER_REQUEST_FAILURE';
