import {put, select, takeEvery} from 'redux-saga/effects';
// import {push} from 'connected-react-router';
import * as types from './types';

import r from '../../config/routes';
import {showConfirmationInfo} from "../app-state/actions";

function* afterCreateClientSuccess() {
  const state = yield select();

  // yield put(push(r.ClientsEdit.replace(':client_id', state.clients.client_id) + '#users'));
  yield put(showConfirmationInfo('Client created!'));
  window.location.href = r.ClientsEdit.replace(':client_id', state.clients.client_id) + '#users';
}

export default function* authSaga() {
  yield takeEvery(types.CREATE_CLIENT_REQUEST_SUCCESS, afterCreateClientSuccess);
}
