import * as types from './types';
// import _ from 'underscore';

const initial = {
  loading: false,
  list: null,
  message: null, // {msg: 'User authenticated', type: 'info'}
};

const notifications = (state = initial, action) => {
  switch (action.type) {
  case types.GET_NOTIFICATIONS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_NOTIFICATIONS_REQUEST_SUCCESS:
    return {
      ...state,
      list: (action.payload.data === null) ? [] : action.payload.data,
      loading: false
    };
  default:
    return state;
  }
};

export default notifications;
