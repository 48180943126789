import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import React from "react";
import {SnackbarContent, withStyles} from "@material-ui/core";
import styles from "../../containers/dashboards/project/project.style";

const ConfirmationInfoComponent = props => {

  const handleClose = () => {
    props.close();
  };
  const {classes} = props;
  return (<Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    autoHideDuration={3000}
    onClose={handleClose}
    open={props.isOpen}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}>
    <SnackbarContent
      className={classes.confirmationInfo}
      message={<span id="message-id">{props.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      ]}
    />
  </Snackbar>)
};

export default withStyles(styles, {withTheme: true})(ConfirmationInfoComponent);
