import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Material components
import { createMuiTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

// Portal components
import logoImage from "../../../../assets/images/paymachine.svg";

// Actions
import { toggleSidenav } from "../../../../actions/layout.actions";

// Styles
import scss from "./sidenav.module.scss";

const Sidenav = props => {
  const { theme, layout } = props;

  const sidenavTheme = createMuiTheme(theme.sidenavTheme);
  const dockedClass = layout.sidenavOpen
    ? scss[`portal-sidenav-${layout.currentLayout}`]
    : "";

  return (
    <MuiThemeProvider theme={sidenavTheme}>
      <Drawer
        variant={layout.sidenavVariant}
        open={layout.sidenavOpen}
        onClose={props.toggleSidenav}
        classes={{
          docked: dockedClass,
          paper: scss[`portal-sidenav-${layout.currentLayout}`]
        }}
      >
        {/* top toolbar with logo */}
        <AppBar color="default" position="static">
          <Toolbar>
            <img
              src={logoImage}
              className={scss["portal-toolbar-brand"]}
              alt="logo"
              style={{ padding: "0px", marginRight: "10px", maxHeight: "32px" }}
            />
            {layout.currentLayout !== "compact" &&
              layout.currentLayout !== "funky" && (
                <Typography
                  className={scss["portal-toolbar-brand-title"]}
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  PayMachine
                </Typography>
              )}
          </Toolbar>
        </AppBar>
        {/* main menu */}
        {props.children}
      </Drawer>
    </MuiThemeProvider>
  );
};

function mapStateToProps(state) {
  return {
    theme: state.theme,
    layout: {
      currentLayout: state.layout.currentLayout,
      sidenavOpen: state.layout.sidenavOpen,
      sidenavVariant: state.layout.sidenavVariant
    }
  };
}

Sidenav.propTypes = {
  toggleSidenav: PropTypes.func.isRequired,
  layout: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired
};

export default connect(
  mapStateToProps,
  {
    toggleSidenav
  }
)(Sidenav);
