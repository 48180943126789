export const GET_CLIENTS_USERS_REQUEST = 'GET_CLIENTS_USERS_REQUEST';
export const GET_CLIENTS_USERS_REQUEST_SUCCESS = 'GET_CLIENTS_USERS_REQUEST_SUCCESS';
export const GET_CLIENTS_USERS_REQUEST_FAILURE = 'GET_CLIENTS_USERS_REQUEST_FAILURE';

export const CREATE_CLIENTS_USERS_REQUEST = 'CREATE_CLIENTS_USERS_REQUEST';
export const CREATE_CLIENTS_USERS_REQUEST_SUCCESS = 'CREATE_CLIENTS_USERS_REQUEST_SUCCESS';
export const CREATE_CLIENTS_USERS_REQUEST_FAILURE = 'CREATE_CLIENTS_USERS_REQUEST_FAILURE';

export const GET_CLIENTS_USER_REQUEST = 'GET_CLIENTS_USER_REQUEST';
export const GET_CLIENTS_USER_REQUEST_SUCCESS = 'GET_CLIENTS_USER_REQUEST_SUCCESS';
export const GET_CLIENTS_USER_REQUEST_FAILURE = 'GET_CLIENTS_USER_REQUEST_FAILURE';

export const UPDATE_CLIENTS_USERS_REQUEST = 'UPDATE_CLIENTS_USERS_REQUEST';
export const UPDATE_CLIENTS_USERS_REQUEST_SUCCESS = 'UPDATE_CLIENTS_USERS_REQUEST_SUCCESS';
export const UPDATE_CLIENTS_USERS_REQUEST_FAILURE = 'UPDATE_CLIENTS_USERS_REQUEST_FAILURE';

export const RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST = 'RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST';
export const RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_SUCCESS = 'RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_SUCCESS';
export const RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_FAILURE = 'RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_FAILURE';
