import _ from 'underscore';

export const hasErrors = (error) => {
  return (error !== null && error !== undefined);
}

export const printErrors = (error) => {
  if (_.isArray(error)) {
    return error.join('. ');
  }

  return error;
}
