import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { createBrowserHistory } from 'history';
import { apiMiddleware } from 'redux-api-middleware';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import './index.css';
import App from './app.component';
// import registerServiceWorker from './registerServiceWorker';

// import { BrowserRouter as Router } from 'react-router-dom';

import sagas from './sagas/index';
import rootReducer from './reducers/index';

WebFont.load({
  google: {
    families: ['Poppins:300,400,400i,500,600,700']
  }
});

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer(history),
  applyMiddleware(
    routerMiddleware(history),
    apiMiddleware,
    sagaMiddleware,
  )
);
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
// registerServiceWorker();
