/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getSuperAdmins = () => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_SUPER_ADMINS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_SUPER_ADMINS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_SUPER_ADMINS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const getSuperAdmin = superAdminId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_SUPER_ADMIN_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_SUPER_ADMIN_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_SUPER_ADMIN_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins/${superAdminId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const createSuperAdmins = data => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_SUPER_ADMINS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_SUPER_ADMINS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_SUPER_ADMINS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      username: data.username,
      // password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone
    })
  }
});

export const updateSuperAdmins = (superAdminId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_SUPER_ADMINS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_SUPER_ADMINS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_SUPER_ADMINS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins/${superAdminId}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone
    })
  }
});

export const changePassword = (superAdminId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CHANGE_PASSWORD_SUPER_ADMINS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins/${superAdminId}/password`,
    method: 'PUT',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      // access_token: localStorage.getItem('AUTH_ACCESS_TOKEN'), ///XXX: We don't need it anymore
      old_password: data.oldPassword,
      new_password: data.password
    })
  }
});

