const styles = theme => {
  return ({
    background: {
      background: theme.palette.secondary.main,
      width: '100%',
      height: '100%',
    },
    'primary-card': {
      background: theme.palette.primary.light,
    }
  });
};

export default styles;
