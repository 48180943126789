import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from '../redux/auth/reducer';
import breadcrumbs from '../redux/breadcrumbs/reducer';
import clients from '../redux/clients/reducer';
import clientsUsers from '../redux/clients-users/reducer';
import settings from '../redux/settings/reducer';
import notifications from '../redux/notifications/reducer';
import superAdmins from '../redux/super-admins/reducer';
import appState from '../redux/app-state/reducer';
import fees from '../redux/fees/reducer';
import lenders from '../redux/lenders/reducer';
import lendersUsers from '../redux/lenders-users/reducer';
import lendersWallets from '../redux/lenders-wallets/reducer';

import layout from '../redux/theme/layout.reducer';
import theme from '../redux/theme/theme.reducer';

export default history => combineReducers({
  router: connectRouter(history),
  appState,
  auth,
  breadcrumbs,
  clients,
  clientsUsers,
  superAdmins,
  notifications,
  settings,
  layout,
  fees,
  lenders,
  lendersUsers,
  lendersWallets,
  theme
});
