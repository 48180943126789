"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var styles = function styles(theme) {
  return {
    root: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'flex',
      paddingBottom: 0,
      paddingTop: 0
    },
    list: {
      backgroundColor: theme.palette.background.paper
    },
    listItem: {
      textAlign: 'left',
      textTransform: 'none',
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%',
      minHeight: 48,
      alignItems: 'center',
      boxSizing: 'border-box',
      paddingLeft: 16,
      paddingRight: 16
    },
    listItemActive: {
      backgroundColor: theme.palette.action.hover
    },
    listItemButtonLabel: {
      flexDirection: 'row'
    },
    listItemText: {
      flex: '1 1 100%',
      textTransform: 'none',
      fontWeight: 300
    },
    listIcon: {
      height: 20,
      paddingLeft: 0,
      paddingRight: 25
    },
    nested: {
      width: '100%'
    }
  };
};

var _default = styles;
exports["default"] = _default;