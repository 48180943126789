import {connect} from 'react-redux'
import ConfirmationInfo from './component'
import {hideConfirmationInfo} from '../../redux/app-state/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.appState.confirmationInfo.show,
    message: state.appState.confirmationInfo.message
  }
};

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideConfirmationInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationInfo)
