import { connect } from 'react-redux';
import Component from './MenuSidenav.component';
import { setSidenavOpen } from '../../../../actions/layout.actions';

const mapStateToProps = state => ({
  auth: state.auth,
  client: state.clients.client,
  clientsSettings: state.clientsSettings,
  variant: state.layout.sidenavVariant
});

const mapDispatchToProps = dispatch => ({
  closeSidenav: () => dispatch(setSidenavOpen(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
