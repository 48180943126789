import * as types from './types';
import _ from 'underscore';

const initial = {
  loading: false,
  clientId: null,
  addedNew: false,
  user: null,
  list: [],
  message: null, // {msg: 'User authenticated', type: 'info'}
};

const clientsUsers = (state = initial, action) => {
  switch (action.type) {
  case types.GET_CLIENTS_USER_REQUEST:
    return {
      ...state,
      user: null,
      loading: true
    };
  case types.GET_CLIENTS_USER_REQUEST_SUCCESS:
    return {
      ...state,
      user: action.payload.data,
      loading: false
    };
  case types.GET_CLIENTS_USERS_REQUEST_SUCCESS:
    return {
      ...state,
      addedNew: false,
      list: (_.isArray(action.payload.data) ? action.payload.data : []),
      loading: false
    };
  case types.CREATE_CLIENTS_USERS_REQUEST:
    return {
      ...state,
      clientId: action.payload,
      loading: true
    };
  case types.UPDATE_CLIENTS_USERS_REQUEST:
    return {
      ...state,
      clientId: action.payload,
      loading: true
    };
  case types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_CLIENTS_USERS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.CREATE_CLIENTS_USERS_REQUEST_SUCCESS:
    return {
      ...state,
      addedNew: true,
      loading: false
    };
  case types.CREATE_CLIENTS_USERS_REQUEST_FAILURE:
    return {
      ...state,
      message: action.payload.message,
      loading: false
    };
  case types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST: {
    return {
      ...state,
      clientId: action.payload,
      loading: true
    };
  }
  case types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false
    };
  }
  case types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false
    };
  }
  default:
    return state;
  }
};

export default clientsUsers;
