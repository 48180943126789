import {put, select, takeEvery} from 'redux-saga/effects';
// import {push} from 'connected-react-router';
import * as types from './types';

import { getWallet } from '../lenders-wallets/actions';

function* afterMarkEntryAsSuccess() {
  const state = yield select();
  yield put(getWallet(state.lendersWallets.lenderId));
}

export default function* () {
  yield takeEvery(types.MARK_DEPOSIT_AS_VERIFIED_REQUEST_SUCCESS, afterMarkEntryAsSuccess);
  yield takeEvery(types.MARK_WITHDRAWAL_AS_DELIVERED_REQUEST_SUCCESS, afterMarkEntryAsSuccess);
}
