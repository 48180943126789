import { API_URL } from './env';

const Lenders = {
  List: '/lenders',
  Show: '/lenders/:lender_id',
  ShowLendersList: '/lenders/:lender_id#lender-admins',
  Create: '/lenders/create',
  Wallets: '/lenders/wallets'
};

const LendersWallets = {
  List: '/lenders-wallets',
  Show: '/lenders-wallets/:lenderId'
};

const LendersUsers = {
  List: '/lenders/:lender_id/users',
  Create: '/lenders/:lender_id/users/create'
};

export default {
  Home: '/',
  Dashboard: '/',
  Login: '/login',
  Register: '/register',
  RegisterConfirm: '/register-confirm/:email/:code',
  ForgotPassword: '/forgot-password',
  ForgotPasswordConfirm: '/forgot-password-confirm/:email/:code',
  SuperAdminsList: '/super-admins',
  SuperAdminsCreate: '/super-admins/new',
  SuperAdminsEdit: '/super-admins/:super_admin_id',
  SuperAdminsEditProfile: '/super-admins/profile',
  ClientsList: '/clients',
  ClientsCreate: '/clients/new',
  ClientsEdit: '/clients/:client_id',
  ClientsUsersCreate: '/clients/:client_id/users/new',
  ClientsUsersEdit: '/clients/:client_id/users/:client_user_id',

  LoansList: '/loans',
  LoansListClient: '/loans/:client_id',
  LoanDetails: '/clients/:client_id/loan/:loan_id',
  InstantPayrollList: '/instant_payroll',
  InstantPayrollListClient: '/instant_payroll/:client_id',
  InstantPayrollDetails: '/clients/:client_id/instant_payroll/:payment_request_id',

  TermsAndConditionsEdit: '/settings/terms-and-conditions',
  Notifications: '/notifications',

  APIClientsListCSV: `${API_URL}/clients/list.csv?_pmatoken=${localStorage.getItem('AUTH_TOKEN')}`,
  APIClientsUsersListCSV: `${API_URL}/clients/:client_id/users.csv?_pmatoken=${localStorage.getItem('AUTH_TOKEN')}`,
  APIPaymentRequestsListCSV: `${API_URL}/clients/:client_id/payment-requests.csv?_pmatoken=:auth_token`,
  LoansListCSV: `${API_URL}/clients-loans/:client_id/traditional-loans.csv?_pmatoken=:auth_token`,

  // Lenders routes
  Lenders,
  // Lender's users
  LendersUsers,
  // Lender's wallet
  LendersWallets
};
