export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_REQUEST_SUCCESS = 'GET_CLIENTS_REQUEST_SUCCESS';
export const GET_CLIENTS_REQUEST_FAILURE = 'GET_CLIENTS_REQUEST_FAILURE';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_REQUEST_SUCCESS = 'GET_CLIENT_REQUEST_SUCCESS';
export const GET_CLIENT_REQUEST_FAILURE = 'GET_CLIENT_REQUEST_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_REQUEST_SUCCESS = 'CREATE_CLIENT_REQUEST_SUCCESS';
export const CREATE_CLIENT_REQUEST_FAILURE = 'CREATE_CLIENT_REQUEST_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_REQUEST_SUCCESS = 'UPDATE_CLIENT_REQUEST_SUCCESS';
export const UPDATE_CLIENT_REQUEST_FAILURE = 'UPDATE_CLIENT_REQUEST_FAILURE';

export const UPDATE_CLIENT_FEE_MODEL_REQUEST = 'UPDATE_CLIENT_FEE_MODEL_REQUEST';
export const UPDATE_CLIENT_FEE_MODEL_REQUEST_SUCCESS = 'UPDATE_CLIENT_FEE_MODEL_REQUEST_SUCCESS';
export const UPDATE_CLIENT_FEE_MODEL_REQUEST_FAILURE = 'UPDATE_CLIENT_FEE_MODEL_REQUEST_FAILURE';

export const GET_LENDERS_REQUEST = 'GET_LENDERS_REQUEST';
export const GET_LENDERS_REQUEST_SUCCESS = 'GET_LENDERS_REQUEST_SUCCESS';
export const GET_LENDERS_REQUEST_FAILURE = 'GET_LENDERS_REQUEST_FAILURE';
