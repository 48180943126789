import React from 'react';
import PropTypes from 'prop-types';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import themeStyles from './layout-loader.theme.style';

const LayoutLoader = (props) => {
  const {
    classes,
    label,
    variant
  } = props;

  let v = 'loader';
  if (undefined !== variant) {
    v = variant;
  }

  let l = 'Loading...';
  if (undefined !== label) {
    l = label;
  }

  return (
    <div className={classes[v + 'Wrapper']}>
      <CircularProgress className={classes.progress} size={50} />
      {l}
    </div>
  );
};

LayoutLoader.propTypes = {
  classes: PropTypes.shape({}).isRequired
};


export default withStyles(themeStyles, { withTheme: true })(LayoutLoader);
