const styles = theme => ({
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: '100%',
    placeContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 200
  },
  absoluteWrapper: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,.05)',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: '100%',
    placeContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 200
  }
});

export default styles;
