import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LogOutIcon from '@material-ui/icons/PowerSettingsNew';

import SettingsIcon from '@material-ui/icons/Settings';

import themes from './themes';

import r from './config/routes';

export const configuredTheme = themes[0].theme;

export const configuredLayout = {
  currentLayout: 'paymachine',
  notificationsOpen: false
};

const iconStyle = {
  fontSize: 20
};

export const menuItems = [
  {
    title: 'QUICK ACCESS',
    type: 'header'
  },
  {
    title: 'Dashboard',
    icon: <DashboardIcon style={iconStyle} />,
    href: r.Home
  },
  {
    title: 'Lenders',
    icon: <AccountCircleIcon style={iconStyle} />,
    children: [{
      title: 'List',
      href: r.Lenders.List
    }, {
      title: 'Wallets',
      href: r.LendersWallets.List
    }]
  },
  {
    title: 'Clients',
    icon: <AccountCircleIcon style={iconStyle} />,
    children: [{
      title: 'All Clients',
      href: r.ClientsList
    },
    {
      title: 'Add new client',
      href: r.ClientsCreate
    }]
  },
  {
    title: 'Instant Payroll Status',
    icon: <NotificationsIcon style={iconStyle} />,
    href: r.InstantPayrollList
  },
  {
    title: 'Loan Status',
    icon: <NotificationsIcon style={iconStyle} />,
    href: r.LoansList
  },
  {
    title: 'Notifications',
    icon: <NotificationsIcon style={iconStyle} />,
    href: r.Notifications
  },
  {
    title: 'Settings',
    icon: <SettingsIcon style={iconStyle} />,
    children: [{
      title: 'Administrators',
      href: r.SuperAdminsList
    },
    {
      title: 'Edit Terms & Conditions',
      href: r.TermsAndConditionsEdit
    },
    {
      title: 'My profile',
      href: r.SuperAdminsEditProfile
    }]
  },
  {
    title: 'Log out',
    icon: <LogOutIcon style={iconStyle} />,
    href: '/logout',
    style: {
      // position: 'absolute',
      // bottom: 0
    },
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();
      localStorage.removeItem('AUTH_ACCESS_TOKEN');
      localStorage.removeItem('AUTH_TOKEN');
      localStorage.removeItem('AUTH_USERNAME');
      localStorage.removeItem('AUTH_ID');
      localStorage.removeItem('AUTH_LOGIN_TIME');
      window.location.href = '/';
    }
  },
];
