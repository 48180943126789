import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import asyncComponent from './components/async.component';
import NoLayout from './layouts/layout-none/layout-none.component';
import Paymachine from './components/layouts/paymachine/layout-paymachine.component';
import r from './config/routes';

// TRY to authenticate
import TryToAuthenticate from './components/TryToAuthenticate/container';

// MAIN APP NAVS
const AsyncLoginPage = asyncComponent(() => import('./components/pages/Login/component'));

const AsyncRegisterPage = asyncComponent(() => import('./components/pages/Register/component'));
const AsyncRegisterConfirmPage = asyncComponent(() => import('./components/pages/RegisterConfirm/component'));

const AsyncForgotPasswordPage = asyncComponent(() => import('./components/pages/ForgotPassword/component'));
const AsyncForgotPasswordConfirmPage = asyncComponent(() => import('./components/pages/ForgotPasswordConfirm/component'));

const AsyncSuperAdminsListPage = asyncComponent(() => import('./components/pages/SuperAdminsList/container'));
const AsyncSuperAdminsCreatePage = asyncComponent(() => import('./components/pages/SuperAdminsCreate/container'));
const AsyncSuperAdminsEditPage = asyncComponent(() => import('./components/pages/SuperAdminsEdit/container'));
const AsyncSuperAdminsEditProfilePage = asyncComponent(() => import('./components/pages/SuperAdminsEditProfile/container'));

const AsyncClientsListPage = asyncComponent(() => import('./components/pages/ClientsList/container'));
const AsyncClientsCreatePage = asyncComponent(() => import('./components/pages/ClientsCreate/container'));
const AsyncClientsEditPage = asyncComponent(() => import('./components/pages/ClientsEdit/container'));

const AsyncClientsUsersCreatePage = asyncComponent(() => import('./components/pages/ClientsUsersCreate/container'));
const AsyncClientsUsersEditPage = asyncComponent(() => import('./components/pages/ClientsUsersEdit/container'));

const AsyncInstantPayrollListPage = asyncComponent(() => import('./components/pages/InstantPayrollListPage/container'));
const AsyncLoansListPage = asyncComponent(() => import('./components/pages/LoansList/container'));
const AsyncInstantPayrollDetailsPage = asyncComponent(() => import('./components/pages/InstantPayrollDetails/container'));
const AsyncLoansDetailsPage = asyncComponent(() => import('./components/pages/LoanDetails/container'));
const AsyncNotificationsPage = asyncComponent(() => import('./components/pages/Notifications/container'));

const AsyncTermsAndConditionsEditPage = asyncComponent(() => import('./components/pages/TermsAndConditionsEdit/container'));

const AsyncNotFound = asyncComponent(() => import('./containers/not-found/not-found.component'));

//Lenders
const LendersList = asyncComponent(() => import('./components/pages/Lenders/List/container'));
const LendersCreate = asyncComponent(() => import('./components/pages/Lenders/Create/container'));
const LendersShow = asyncComponent(() => import('./components/pages/Lenders/Show/container'));

//LendersWallets
const LendersWalletsList = asyncComponent(() => import('./components/pages/LendersWallets/List/container'));

//LendersUsers
const LendersUsersList = asyncComponent(() => import('./components/pages/LendersUsers/List/container'));
const LendersUsersCreate = asyncComponent(() => import('./components/pages/LendersUsers/Create/container'));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
  >
    <Layout>
      <Component {...rest} />
    </Layout>
  </Route>
);

const PaymachineLayout = props => (
  <Paymachine>{props.children}</Paymachine>
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps, layout }) => {
  let activeLayout;
  switch (layout.currentLayout) {
  case 'paymachine':
    activeLayout = PaymachineLayout;
    break;
  default:
    activeLayout = PaymachineLayout;
  }

  return (
    <Router>
      <TryToAuthenticate>
        <Switch>
          <AppRoute path={r.Home} exact component={AsyncClientsListPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.Login} exact component={AsyncLoginPage} props={childProps} layout={NoLayout} />
          <AppRoute path={r.Register} exact component={AsyncRegisterPage} props={childProps} layout={NoLayout} />
          <AppRoute path={r.RegisterConfirm} exact component={AsyncRegisterConfirmPage} props={childProps} layout={NoLayout} />
          <AppRoute path={r.ForgotPassword} exact component={AsyncForgotPasswordPage} props={childProps} layout={NoLayout} />
          <AppRoute path={r.ForgotPasswordConfirm} exact component={AsyncForgotPasswordConfirmPage} props={childProps} layout={NoLayout} />

          <AppRoute path={r.SuperAdminsList} exact component={AsyncSuperAdminsListPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.SuperAdminsCreate} exact component={AsyncSuperAdminsCreatePage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.SuperAdminsEditProfile} exact component={AsyncSuperAdminsEditProfilePage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.SuperAdminsEdit} exact component={AsyncSuperAdminsEditPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.ClientsList} exact component={AsyncClientsListPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.ClientsCreate} exact component={AsyncClientsCreatePage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.ClientsEdit} exact component={AsyncClientsEditPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.ClientsUsersCreate} exact component={AsyncClientsUsersCreatePage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.ClientsUsersEdit} exact component={AsyncClientsUsersEditPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.TermsAndConditionsEdit} exact component={AsyncTermsAndConditionsEditPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.InstantPayrollList} exact component={AsyncInstantPayrollListPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.InstantPayrollListClient} exact component={AsyncInstantPayrollListPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.InstantPayrollDetails} exact component={AsyncInstantPayrollDetailsPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.LoansList} exact component={AsyncLoansListPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.LoanDetails} exact component={AsyncLoansDetailsPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.LoansListClient} exact component={AsyncLoansListPage} props={childProps} layout={activeLayout} />
          
          <AppRoute path={r.Notifications} exact component={AsyncNotificationsPage} props={childProps} layout={activeLayout} />

          <AppRoute path={r.Lenders.List} exact component={LendersList} props={childProps} layout={activeLayout} />
          <AppRoute path={r.Lenders.Create} exact component={LendersCreate} props={childProps} layout={activeLayout} />
          <AppRoute path={r.Lenders.Show} exact component={LendersShow} props={childProps} layout={activeLayout} />

          <AppRoute path={r.LendersWallets.List} exact component={LendersWalletsList} props={childProps} layout={activeLayout} />
          <AppRoute path={r.LendersWallets.Show} exact component={LendersWalletsList} props={childProps} layout={activeLayout} />

          <AppRoute path={r.LendersUsers.List} exact component={LendersUsersList} props={childProps} layout={activeLayout} />
          <AppRoute path={r.LendersUsers.Create} exact component={LendersUsersCreate} props={childProps} layout={activeLayout} />

          <AppRoute component={AsyncNotFound} layout={activeLayout} />
        </Switch>
      </TryToAuthenticate>
    </Router>
  );
};
