export const TRY_TO_AUTHENTICATE = 'TRY_TO_AUTHENTICATE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE';

export const REGISTER_CONFIRM_REQUEST = 'REGISTER_CONFIRM_REQUEST';
export const REGISTER_CONFIRM_REQUEST_SUCCESS = 'REGISTER_CONFIRM_REQUEST_SUCCESS';
export const REGISTER_CONFIRM_REQUEST_FAILURE = 'REGISTER_CONFIRM_REQUEST_FAILURE';

export const LOGOUT = 'LOGOUT';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';

export const RESET_PASSWORD_CONFIRM_REQUEST = 'RESET_PASSWORD_CONFIRM_REQUEST';
export const RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS = 'RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS';
export const RESET_PASSWORD_CONFIRM_REQUEST_FAILURE = 'RESET_PASSWORD_CONFIRM_REQUEST_FAILURE';
